import React from 'react';

import AppGoGetLayout from '../../components/AppGoGetLayout';
import HeaderSeo from '../../components/HeaderSeo';
import JobPageAppBar from '../../views/Jobs/JobPageAppBar';
import JobPageFooter from '../../views/Jobs/JobPageFooter';
import JobSearch from '../../views/Jobs/JobSearch';

const JobListIndex = () => {
  const handleClickOpenApp = label => {
    const url = `${process.env.GATSBY_GOGETTER_APP_URL}/home`;
    window &&
      window.analytics &&
      window.analytics.track('open_app_now', {
        label,
        page_type: 'job_listing_page',
        platform: 'static'
      });

    window.open(url, '_blank');
  };

  return (
    <AppGoGetLayout logoUrl="/gogetters" disableAppBar>
      <HeaderSeo title="Find Jobs on GoGet | GoGet Jobs" />
      <JobPageAppBar
        handleClickOpenApp={handleClickOpenApp}
        logoUrl="/gogetters"
      />
      <JobSearch
        blurb={{
          title: 'All jobs on GoGet',
          description:
            'From Hourly Gig to Full Time jobs, find flexible work opportunities that suit you. Start earning today!'
        }}
      />
      <JobPageFooter handleClickOpenApp={handleClickOpenApp} />
    </AppGoGetLayout>
  );
};

export default JobListIndex;
